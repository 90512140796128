* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
}
.homePage {
  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
   background-image: url(./beer.jpg);
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   width: 100%;
   height: 100vh;
   opacity: 0.8;
  overflow: hidden;
  margin-bottom: 0%;
 
  h1 {
    font-size: 70px;
    font-weight: bold;
    padding-bottom: 0px;
    text-align: center;
    color:#ffff;
    
    
    }
    h4{
      font-size: 40px;
      font-weight: bold;
      padding-bottom: 10px;
      text-align: center;
      color: #ffff;
      
    }

  

  button {
    color: red;
    background-color:white;

    margin: 50px 480px;
    padding: 10px 1px 1px;
    width: 250px;
    font-size: 40px;
    text-decoration: none;
    border: 2px solid #000000;
    border-radius: 20px;
  }
}


@media screen and (min-width: 1440px) {
 
  .homePage{
    width: 100%;
  }

  .img{
   display: none;
  }

}